<template>
    <div class="wrap">
        <CancelModal v-if="cancelStatus" />
        <popup-modal v-if="isPopUpModal" :text="popupModalText" @close-modal="onCloseModal" />
        <popup-modal v-if="isPopUpModalError" :text="popupModalText" @close-modal="isPopUpModalError = false" />
        <CancelCheckModal
            v-if="cancelCheckStatus"
            :curationinfo="curationObject"
            :isRegistCard="isRegistCard"
            :spaceInfo="spaceInfo"
            @closeCancel="cancelCheckStatus = false"
            @showCancel="cancelStatus = true"
            @deletspace="onDeleteSpace"
        />
        <div class="space_pc">
            <CurationInfo
                :curationInfo="curationInfo"
                v-if="showCurationInfo"
                @closeCurationInfo="showCurationInfo = false"
            />
            <section class="main myspace w_940">
                <div class="main_box my_space3">
                    <div class="main_box_title">
                        <h1 style="margin-bottom: 0px">스페이스 등록</h1>
                        <div @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content qration">
                            <div class="form_text">
                                <h3 style="margin-bottom: 0px">주로 방문하는 고객층은 누구신가요?</h3>
                            </div>
                            <div class="pagnation">
                                <span></span>
                                <span style="width: 48%"></span>
                            </div>
                            <div class="three_type fade-up">
                                <div>
                                    <ul class="acodian_tab" style="padding-left: 0px" @click="clickTarget()">
                                        <li
                                            style="background: url('/media/img/unsplash_XIFuymJydII.png') no-repeat center 0; background-size: cover"
                                        >
                                            <input type="checkbox" v-model="target" value="1" hidden />
                                            <p>유아/아동</p>
                                            <div :class="{ on: selectItem[0] }">
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div>
                                        </li>
                                        <li style="background: url('/media/img/image 1715.png') no-repeat center 0; background-size: cover">
                                            <input type="checkbox" v-model="target" value="2" hidden />
                                            <p>청소년</p>
                                            <div :class="{ on: selectItem[1] }">
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div>
                                        </li>
                                        <li
                                            style="background: url('/media/img/unsplash_yk-CgvlrK1g.png') no-repeat center 0; background-size: cover"
                                        >
                                            <input type="checkbox" v-model="target" value="3" hidden />
                                            <p>청년</p>
                                            <div :class="{ on: selectItem[2] }">
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div>
                                        </li>
                                        <li
                                            style="background: url('/media/img/unsplash_6anudmpILw4.png') no-repeat center 0; background-size: cover"
                                        >
                                            <input type="checkbox" v-model="target" value="4" hidden />
                                            <p>중장년</p>
                                            <div :class="{ on: selectItem[3] }">
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div>
                                        </li>
                                        <li
                                            style="background: url('/media/img/unsplash_yRmmm3t1oX8.png') no-repeat center 0; background-size: cover"
                                        >
                                            <input type="checkbox" v-model="target" value="5" hidden />
                                            <p>노년</p>
                                            <div :class="{ on: selectItem[4] }">
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="form_bottom space_1">
                                <div class="form_bottom_box">
                                    <div>
                                        <svg
                                            width="36"
                                            height="36"
                                            viewBox="0 0 36 36"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            @click="showCurationInfo = true"
                                        >
                                            <path
                                                d="M18.636 16.828L13.686 21.778L12.272 20.364L18.636 14L25 20.364L23.586 21.778L18.636 16.828Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    <div class="space_bottom_list">
                                        <div
                                            class="none"
                                            v-for="(item, index) in bottomList"
                                            :key="`bottom${index}`"
                                            :style="`z-index:${bottomList.length - index}`"
                                        >
                                            <label v-if="item.cssclass === 'curation_info_box material'" :class="item.cssclass">
                                                {{ item.url }}
                                            </label>
                                            <figure v-else :class="item.cssclass">
                                                <img class="curation_info_box_img" :src="item.url" />
                                            </figure>
                                        </div>
                                    </div>
                                    <div>
                                        <button id="prev_btn" style="text-align: center" @click="clickPre()">이전</button>
                                        <button
                                            id="next_btn"
                                            style="text-align: center"
                                            v-if="target.targetNo.length > 0"
                                            @click="updateCuration()"
                                        >
                                            다음
                                        </button>
                                        <button v-else style="text-align: center; color: #5b5b5b; background: #4d1b24" disabled>
                                            다음
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo h_100">
            <CurationInfoM
                :curationInfo="curationInfo"
                v-if="showCurationInfoM"
                @closeCurationInfo="showCurationInfoM = false"
            />
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                    </a>
                    <h2>스페이스 등록</h2>
                    <div class="btn_close">
                        <img src="/media/images/icon_close_24.svg" @click="linkMySpace()" />
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span style="width: 48%"></span>
                </div>
            </div>
            <section class="main myspace">
                <div class="main_box">
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3 style="margin-bottom: 0px">주로 방문하는 고객층은 누구신가요?</h3>
                        </div>
                        <div class="types" @click="clickTargetMo()">
                            <ul style="padding-left: 0px">
                                <li
                                    style="background: url('/media/img/unsplash_XIFuymJydII.png') no-repeat center 0; background-size: cover"
                                >
                                    <span :style="{ display: selectItem[0] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="target" value="1" hidden />
                                    <p style="margin-bottom: 0px">유아/아동</p>
                                </li>
                                <li style="background: url('/media/img/image 1715.png') no-repeat center 0; background-size: cover">
                                    <span :style="{ display: selectItem[1] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="target" value="2" hidden />
                                    <p style="margin-bottom: 0px">청소년</p>
                                </li>
                                <li
                                    style="background: url('/media/img/unsplash_yk-CgvlrK1g.png') no-repeat center 0; background-size: cover"
                                >
                                    <span :style="{ display: selectItem[2] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="target" value="3" hidden />
                                    <p style="margin-bottom: 0px">청년</p>
                                </li>
                                <li
                                    style="background: url('/media/img/unsplash_6anudmpILw4.png') no-repeat center 0; background-size: cover"
                                >
                                    <span :style="{ display: selectItem[3] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="target" value="4" hidden />
                                    <p style="margin-bottom: 0px">중장년</p>
                                </li>
                                <li
                                    style="background: url('/media/img/unsplash_yRmmm3t1oX8.png') no-repeat center 0; background-size: cover"
                                >
                                    <span :style="{ display: selectItem[4] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="target" value="5" hidden />
                                    <p style="margin-bottom: 0px">노년</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="types_bottom">
                        <svg
                            @click="showInfo('target')"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.3025 2.92566L2.3525 7.87566L0.938504 6.46166L7.3025 0.0976563L13.6665 6.46166L12.2525 7.87566L7.3025 2.92566Z"
                                fill="white"
                            />
                        </svg>
                        <div class="mo_space_bottom_list">
                            <div
                                class="none"
                                v-for="(item, index) in bottomList"
                                :key="`bottom${index}`"
                                :style="`z-index:${bottomList.length - index}`"
                            >
                                <label v-if="item.cssclass === 'curation_info_box material'" :class="item.cssclass">
                                    {{ item.url }}
                                </label>
                                <figure v-else :class="item.cssclass">
                                    <img class="curation_info_box_img" :src="item.url" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div class="form_bottom">
                        <button style="text-align: center" v-if="target.targetNo.length > 0" @click="updateCuration()">다음</button>
                        <button class="opacity3" style="text-align: center" v-else>다음</button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import CurationInfoM from '@/components/modal/Space/CurationInfoM.vue';
import CurationInfo from '@/components/modal/Space/CurationInfo.vue';
import CancelCheckModal from '@/components/modal/Space/CancelCheckModal.vue';
import CancelModal from '@/components/modal/Space/CancelModal.vue';
import $ from 'jquery';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import router from '@/router';
import PopUpModal from '@/components/modal/common/PopUpModal.vue';
import { updateConfirmCuration } from '@/service/api/profileApi';
export default defineComponent({
  data () {
    return {
      curationObject: {},
      isRegistCard: true,
      cancelStatus: false,
      cancelCheckStatus: false,
      showCurationInfo: false,
      showCurationInfoM: false,
      target: {
        targetNo: []
      },
      curationInfo: {
        infoStatus: '',
        infoCategory: '',
        mood: ''
      },
      curationList: [],
      tempList: [],
      allList: ['유아/아동', '청소년', '청년', '중장년', '노년'],
      selectItem: [],
      filterList: [],
      bottomList: [],
      popupModalText: '',
      isPopUpModal: false,
      isPopUpModalError: false,
      spaceId: localStorage.getItem('spaceId')
    };
  },
  components: {
    CurationInfo,
    CancelModal,
    CancelCheckModal,
    CurationInfoM,
    'popup-modal': PopUpModal
  },
  methods: {
    onDeleteSpace (msg) {
      this.isPopUpModal = true;
      this.popupModalText = msg;
    },
    checkRegistCard () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/product/getMyCardInfo', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? (this.isRegistCard = true) : (this.isRegistCard = false);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    showInfo (index) {
      this.curationInfo.infoStatus = index;
      this.curationInfo.infoCategory = 'insert';
      this.showCurationInfoM = true;
    },
    linkMySpace () {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/checkSubscribe', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            if (res.data.result === 'S') {
              this.cancelCheckStatus = true;
              this.curationObject = this.filterCurationInfo();
            } else {
              router.push({ name: 'Player' });
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    filterCurationInfo () {
      var spaceId = localStorage.getItem('spaceId');
      var target = this.target.targetNo
        .sort((a, b) => {
          return a - b;
        })
        .toString();
      let array = [];
      this.target.targetNo.forEach(val => {
        const item = this.$store.getters['player/getTargetUnitFilter'](val);
        array = [...array, ...item];
      });
      const set = new Set(array);
      const targetUnit = Array.from(set).join(',');

      return {
        spaceId,
        target,
        targetUnit
      };
    },
    async updateCuration () {
      try {
        var aToken = VueCookies.get('aToken');
        var headers = { 'X-AUTH-TOKEN': aToken };
        const filter = this.filterCurationInfo();

        const { data: curationTempData } = await axios.post('/api/profile/updateCurationTemp', filter, { headers });

        const { resultCd } = curationTempData;
        if (resultCd === '0000') {
          const { data: confirmCurationData } = await updateConfirmCuration(this.spaceId, this.curationInfo.curationId);
          const { resultCd: confirmCurationResultCd } = confirmCurationData;
          if (confirmCurationResultCd === '0000') {
            this.$router.push({ name: 'SpaceInsertStoreCountry' });
          }
        }
      } catch (err) {
        if (err.response) {
          const { data } = err.response;
          const { resultCd, resultMsg } = data;
          if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
            this.popupModalText = resultMsg;
            this.isPopUpModal = true;
            return false;
          } else if (resultCd === '500') {
            this.popupModalText = resultMsg;
            this.isPopUpModalError = true;
            return false;
          }
          console.log('err.response : ', err.response);
          console.log(err);
        }
      }
    },
    onCloseModal () {
      this.isPopUpModal = false;
      this.$router.push({ name: 'MySpace' });
    },
    clickPre () {
      this.$router.push({ name: 'SpaceInsertStoreMaterial' });
    },
    clickTarget () {
      $(document)
        .off('click')
        .on('click', '.acodian_tab > li', e => {
          // $(".acodian_tab > li").children("div").removeClass("on");
          $(e.currentTarget)
            .children('div')
            .toggleClass('on');
          // target 값 넣고 빼기
          var targetList = this.target.targetNo;
          var target = parseInt(e.currentTarget.querySelector('input').value);

          var found = targetList.find(el => el === target);
          if (found != null) {
            // 선택한 값이 있을때
            var filtered = targetList.filter(el => el !== target);
            this.target.targetNo = filtered;
          } else {
            // 선택한 값이 없을때
            this.target.targetNo.push(target);
          }
        });
    },
    set () {
      $('.types ul li')
        .children('span')
        .hide();
    },
    clickTargetMo () {
      $(document)
        .off('click')
        .on('click', '.types ul li', e => {
          $(e.currentTarget)
            .children('span')
            .toggle();
          // target 값 넣고 빼기
          var targetList = this.target.targetNo;
          var target = parseInt(e.currentTarget.querySelector('input').value);

          var found = targetList.find(el => el === target);
          if (found != null) {
            // 선택한 값이 있을때
            var filtered = targetList.filter(el => el !== target);
            this.target.targetNo = filtered;
          } else {
            // 선택한 값이 없을때
            this.target.targetNo.push(target);
          }
        });
    },
    openSelectBoxMo () {
      $('.types_bottom')
        .find('svg')
        .click(function () {
          $('.space_down_box1').css({ bottom: '0%', transition: 'all 1s' });
        });
    },
    closeSelectBoxMo () {
      $('.space_down_box1')
        .find('svg')
        .click(function () {
          $('.space_down_box1').css({ bottom: '-100%', transition: 'all 1s' });
        });
    },
    getCurationInfo () {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          // 소재
          this.curationInfo = res.data.result;
          if (res.data.result.material) {
            //
            res.data.result.material.forEach(ele => {
              const findMaterial = this.$store.getters['curation/getMaterialValue'](ele);
              if (findMaterial) {
                this.bottomList.push({
                  cssclass: 'curation_info_box material',
                  url: findMaterial.materialNm
                });
              }
            });
          }
          // 색상
          if (res.data.result.color) {
            //
            const findColor = this.$store.getters['curation/getColorItem'](res.data.result.color);
            this.bottomList.push({
              cssclass: 'curation_info_box color',
              url: `${findColor.img}`
            });
          }
          this.tempList = res.data.result.target;

          this.curationInfo.mood = res.data.result.mood;
          this.curationList = [this.curationInfo.mood];

          if (res.data.result.target != null) {
            this.selectTarget();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    selectTarget () {
      var allList = this.allList;
      for (var i in allList) {
        this.selectItem[i] = this.tempList.indexOf(allList[i]) > -1;

        var filter = allList.indexOf(this.tempList[i]) + 1;
        if (filter > 0) {
          this.filterList.push(filter);
        }
      }
      this.target.targetNo = this.filterList;
    }
  },
  mounted () {
    document.querySelector('html').scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    this.getCurationInfo();
    this.checkRegistCard();
  }
});
</script>

<style scoped>
.select_curation {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.select_curation p {
  padding: 10px 12px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px 8px;
  white-space: nowrap;
}

.types_bottom > svg {
  height: 15px;
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style scoped src="@/assets/css/spacemanage/spaceinsert.css"></style>
